<template>
  <b-modal
    visible
    scrollable
    centered
    title="Экспорт обращений"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div
      v-else
      class="appeals-export"
    >
      <div class="crm-form-row">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <div class="crm-label">
              Дата создания
            </div>
            <div class="crm-form-field-wrapper">
              <b-form-input
                v-model="exportData.dateFrom"
                type="date"
                placeholder=""
                :max="formatDateForInput"
                size="sm"

                class="crm-form-date-picker"
                @keyup.native.enter="$emit('search')"
              />
              <div class="crm-form-divider" />
              <b-form-input
                v-model="exportData.dateTo"
                type="date"
                placeholder=""
                :max="formatDateForInput"
                size="sm"
                class="crm-form-date-picker"
                @keyup.native.enter="$emit('search')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="crm-form-row">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-label">
              Статус обращения
            </p>
            <v-select
              v-model="exportData.status"
              :reduce="status => status.id"
              :options="statuses"
              label="title"
              placeholder="Выберите статус"
            />
          </div>
        </div>
      </div>
      <div class="crm-form-row">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-label">
              Ответственный
            </p>
            <v-select
              v-model="exportData.responsibleId"
              :reduce="responsible => responsible.id"
              :options="responsibles"
              label="displayName"
              placeholder="Выберите ответственного"
            />
          </div>
        </div>
      </div>
      <div class="crm-wrapper-buttons">
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="crm-button"
          @click="onExport"
        >
          Экспортировать
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
// BaseDatePicker,
} from '@/components/base';
import Loading from '@/components/Loading';

import { APPEALS_STATUSES_TEXTS } from '@/services/appeals/appeals.const';
import { saveFile } from '@/helpers/utils';
import { accountService, appealsService } from '@/services';
import { showErrorMessageGetUsers } from '@/helpers/messages';

import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'AppealsExportModal',
  components: {
    // BaseDatePicker,
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isSaving: false,
      isLoading: false,
      exportData: {
        dateFrom: null,
        dateTo: null,
        status: null,
        responsibleId: null,
      },
      statuses: APPEALS_STATUSES_TEXTS,
      responsibles: [],
    };
  },
  computed: {
    formatDateForInput() {
      return new Date().toISOString().split('T')[0];
    },
  },
  async created() {
    this.isLoading = true;
    this.responsibles = await this.fetchResponsibles();
    this.isLoading = false;
  },
  methods: {
    async onExport() {
      try {
        this.isSaving = true;
        const {
          dateFrom,
          dateTo,
          responsibleId,
          status,
        } = this.exportData;

        const requestObject = {
          responsible: responsibleId || undefined,
          status: status ?? undefined,
          dateFrom: this.formatDateWithTime(dateFrom) || undefined,
          dateTo: this.formatDateWithTime(dateTo, true) || undefined,
        };

        await this.exportNewAppeals(requestObject);
      } catch (error) {
        console.dir(error);
        console.error(error);
      } finally {
        this.isSaving = false;
      }
    },
    async exportNewAppeals(obj) {
      try {
        const response = await appealsService.export(obj);
        const from = obj.dateFrom ? format(parseISO(obj.dateFrom), 'dd.MM.yyyy') : null;
        const to = obj.dateTo ? format(parseISO(obj.dateTo), 'dd.MM.yyyy') : null;

        let fileName = 'Выгрузка обращений';
        if (from && to) fileName = `Выгрузка обращений ${from} - ${to}.xlsx`;
        if (from && !to) fileName = `Выгрузка обращений от ${from}.xlsx`;
        if (!from && to) fileName = `Выгрузка обращений до ${to}.xlsx`;

        saveFile(response.data, { name: fileName });
      } catch (err) {
        console.log(err);
      }
    },
    formatDateWithTime(date, isEndOfDay = false) {
      const formattedDate = new Date(date);

      if (isEndOfDay) {
        formattedDate.setHours(23, 59, 0, 1);
      } else {
        formattedDate.setHours(0, 0, 0, 1);
      }

      this.addHours(formattedDate, 3);

      return formattedDate.toISOString();
    },
    addHours(date, quantity) {
      return date.setHours(date.getHours() + quantity);
    },
    onClose() {
      this.$emit('input', false);
    },
    async fetchResponsibles() {
      try {
        const responsibles = await accountService.getUsers({ take: 10000 });
        return responsibles;
      } catch (e) {
        console.warn(e);
        showErrorMessageGetUsers();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appeals-export{
  padding: 8px;
}
.crm-wrapper-buttons {
  margin-top: 30px;
}

.crm-form-field-wrapper {
  display: flex;
  align-items: center;

  .crm-form-divider {
    width: 30px;
    height: 1px;
    background-color: #323232;
    margin: 0 10px;
  }
}

.crm-form-row + .crm-form-row {
  margin-top: 20px;
}
</style>
